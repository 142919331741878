.contactInfoRow {
  margin-bottom: 20px;
}

.bottomLine {
  height: 2px;
  background-color: #d8dadf;
  margin: 20px 0;
}

.noteWrapper {
  margin: 0 0 20px 0;
}
