.new, .inProgress {
    background-color: #FF8C00;
}

.completed {
    background-color: #008000;
}

.onHold {
    background-color: #000000;
}

.waitingForFeedback, .qa, .readyForProduction, .escalated, .wontFix {
    background-color: #FF00FF;
}

