.contactLine {
  display: flex;
  flex-direction: row;
  word-break: break-word;
}

.separator {
  height: 15px;
  width: 1px;
  background-color: #d8dadf;
  margin: 0 20px;
}

.wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  display: inline-block;
  margin-right: 10px;
}

.arrangedButtons {
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
}

.arrangedButton {
  min-width: 180px;
  margin: 0 20px;
}

/*.arrangedButton:first-child {*/
/*  margin: 0 20px 0 0;*/
/*}*/

/*.arrangedButton:last-child {*/
/*  margin: 0 0 0 20px;*/
/*}*/

.gap {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .arrangedButton {
    margin: 10px 4px !important;
  }

  .arrangedButtons {
    flex-direction: column;
    justify-content: space-around;
    height: 200px;
    width: max-content;
    margin: 30px auto 0 auto;
  }

  .separator {
    display: none;
  }
}
