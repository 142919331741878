* { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
*:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
a{ 
    text-decoration: none; 
    border-radius: 50% !important;
    height: 35px;
    width: 35px !important;
    outline: none; 
    top: 10px !important; 
    color: inherit; 
    -o-transition: .5s;
    -moz-transition: .5s; 
    -webkit-transition: .5s;
    transition: .5s; 
    cursor: pointer;

}
a:hover, a:focus { text-decoration: none; outline: none;  }
.modalbox {
    z-index: 5;
    width: 90%;
    position: fixed;
    padding: 0px;
    margin: 30px 0px 0px 0px !important;
    background: rgb(255, 255, 255);
    left: calc((100% - 90%) / 2);
    height: 90vh;
    top: 0px;
    box-shadow: 3px 3px 2em black, -3px 0 2em black;
}
.modalbox > div {
    width: 90%;
    position: relative;
    left: calc((100% - 90%) / 2);
    top: 14px;
    z-index: 44;
    height: 98%;
}
.modalbox > div > a{
    right: -28px !important;
    top: -25px !important;
    line-height: 34px !important;
}
.recnot_screen {
    display: block;
    width: 100%;
    margin: 0px auto;
    background-color:rgb(255, 255, 255);
    height: 80vh;
}
.recnot_cont {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
	padding:24px 20px;
    font-family: sans-serif;
    overflow: scroll;
}
.rfcln_box {
    float: left;
    display: inline-block;
    width: 100%;
    font-family: sans-serif;
}
.rfcln_tpdesc {
    float: left;
    width: 100%;
}
.rfcln_tpdesc > h4 {
    display: block;
    font-size: 30px;
    line-height: 34px;
    padding: 0px;
    margin: 0px 0px 20px 0px;
}
.rfcln_tpdesc > p {
    float: left;
    width: 100%;
    border-left: 5px solid #0a6eb5;
    padding-left: 20px;
    font-size: 16px;
    line-height: 20px;
}
ul.rfcln_list {
    float: left;
    display: list-item;
    margin-top: 30px;
    list-style-type: none;
    width: 100%;
    padding: 0px 20px;
}
ul.rfcln_list > li {
    padding: 16px 12px;
    background: #f1f1f1;
	margin-bottom: 25px;
	color:#444444;
}

@media only screen and (max-width: 1024px) {

}
@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }