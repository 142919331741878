@font-face {
  font-family: 'Futura Medium';
  src: url('./assets/fonts/FuturaMedium.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Futura Bold';
  src: url('./assets/fonts/FuturaBold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0;
}

body {
  margin: 0;
  font-family: 'Futura Medium', sans-serif;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

@media (max-width: 768px) {
  fieldset {
    width: 100%;
  }
}

@font-face {
  font-family: 'Futura Book';
  src: url('assets/fonts/FuturaBook.ttf') format('truetype');
}

@font-face {
  font-family: 'Futura Bold';
  src: url('assets/fonts/FuturaBold.otf');
}

@font-face {
  font-family: 'Futura Medium';
  src: url('assets/fonts/FuturaMedium.otf');
}

@font-face {
  font-family: 'Futura Heavy';
  src: url('assets/fonts/FuturaHeavy.ttf') format('truetype');
}
@font-face {
  font-family: 'Futura Bold Italic';
  src: url('assets/fonts/FuturaBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Futura Condensed Extra Bold Italic';
  src: url('assets/fonts/FuturaCondensedExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Futura Extra Bold';
  src: url('assets/fonts/FuturaExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Futura Bold Regular';
  src: url('assets/fonts/FuturaBoldRegular.ttf') format('truetype');
}
/*For Pop-Up*/
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.App {
  width: 90%;
  max-width: 700px;
  margin: 2em auto;
}
p.lorem-text {
  text-align: justify;
}

.modal-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transform: translateZ(0);
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2.5em 1.5em 1.5em 1.5em;
  background-color: #ffffff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (min-width: 500px) {
  /* Center the Modal! */
  .modal-area {
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    max-width: 30em;
    max-height: calc(100% - 1em);
  }
}

._modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  line-height: 1;
  background: #f6f6f7;
  border: 0;
  box-shadow: 0;
  cursor: pointer;
}

._modal-close-icon {
  width: 25px;
  height: 25px;
  fill: transparent;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 2;
}

.modal-body {
  padding-top: 0.25em;
}
._hide-visual {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.scroll-lock {
  overflow: hidden;
  margin-right: 17px;
}

