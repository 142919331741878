.emptyPageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

@media (max-width: 768px) {
  .emptyPageWrapper {
    padding: 20px;
    margin-top: 20px;
  }
  .emptyPageHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    padding: 0 20px;
    text-align: center;
  }

  .warrantyButton {
    width: 100%;
    height: 45px;
  }

  .emptyPageIcon {
    margin-bottom: 20px;
  }
}

@media (max-width: 340px) {
  .emptyPageHeader {
    padding: 0;
  }
}
