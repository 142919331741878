.modalBg {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 6;
  top: 0;
  left: 0;
}

.modalBox,
.modalBoxContract {
  overflow: auto;
  left: 50%;
  max-width: 900px;
  top: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  background-color: #ffffff;
}
.modalBoxContract {
  max-width: 1100px;
}
.modalBoxSmall {
  width: 50%;
  height: 70%;
}

.modalBoxBig {
  width: 80%;
  height: 100%;
}

.sunBox {
  background-image: url('../../../assets/shapes/shape_top-modale.svg');
  background-position: bottom;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sunBoxBig {
  background-size: cover;
  height: 250px;
}

.sunBoxSmall {
  height: 150px;
}

.input:disabled {
  color: rgb(138, 145, 152)!important;
  background-color: #f0f0f0!important;
}

/** Mobile Safari-specific instructions **/
@supports (-webkit-overflow-scrolling: touch) {
  .input:disabled {
    opacity: 1;
    -webkit-opacity: 1;
    -webkit-appearance:none;
  }
}

.mainContent {
  padding: 40px 0;
  display: flex;
  justify-content: center;
  min-height: calc(100% - 200px);
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .modalBoxSmall,
  .modalBoxBig {
    width: 100%;
    height: calc(100% - 60px);
    top: 55%;
    border-radius: 15px 15px 0 0;
  }

  .sunBox {
    height: 200px;
    border-radius: 15px 15px 0 0;
    box-sizing: border-box;
    background-clip: content-box;
  }
}

@media (max-height: 568px) {
  .modalBox {
    top: 60%;
  }
}

@media (max-width: 320px) {
  .mainContent {
    min-height: calc(100% - 150px);
  }

  .sunBox {
    height: 150px;
  }
}
